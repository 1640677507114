import React, { useEffect } from "react"
import { Link } from "gatsby"

import "./CardCarousel.css"

const CardCarousel = ({ cards }) => {
  const setCurrentSlide = (event, id) => {
    if (typeof document === "undefined") return

    const slides = document.querySelectorAll(".card-carousel-container")
    slides.forEach(slide => {
      slide.style.opacity = 0
      slide.querySelector("a").style.zIndex = 0
    })

    const slide = document.getElementById(id)
    slide.style.opacity = 1
    slide.querySelector("a").style.zIndex = 1

    const thumbs = document.querySelectorAll(".card-carousel-thumbnails-img")
    thumbs.forEach(slide => (slide.style.border = "3px solid transparent"))

    const thumb = event.target
    thumb.style.border = "3px solid #fff"
  }

  const setInitialSlide = () => {
    if (typeof document === "undefined") return

    const slides = document.querySelectorAll(".card-carousel-container")
    const s = slides[0]
    s.style.opacity = 1
    s.querySelector("a").style.zIndex = 1

    const thumbs = document.querySelectorAll(".card-carousel-thumbnails-img")
    const t = thumbs[0]
    t.style.border = "3px solid #fff"
  }

  useEffect(() => {
    setInitialSlide()
  })

  return (
    <div className="card-carousel">
      {cards.map(card => (
        <div
          className={`card-carousel-container`}
          id={card.slug}
          style={{
            backgroundImage: `linear-gradient(rgba(245, 246, 252, 0.52), rgb(0 0 0 / 93%)), url(${card.imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="card-carousel-content">
            <span>FEATURED</span>
            <h2>{card.name}</h2>
            <Link to={`/en/cards/${card.slug}`}>READ ARTICLE</Link>
          </div>
        </div>
      ))}

      <div className="card-carousel-thumbnails">
        {cards.map(card => (
          <div
            className="card-carousel-thumbnails-img"
            onClick={e => setCurrentSlide(e, card.slug)}
            style={{
              width: 120,
              height: 80,
              backgroundImage: `url(${card.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default CardCarousel
