import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/en/Layout"
import FeaturedSlider from "../../components/en/FeaturedSlider"
import CardCarousel from "../../components/en/CardCarousel"
import HomeSection from "../../components/en/HomeSection"
import PopularCard from "../../components/en/PopularCard"
import SEO from "../../components/en/SEO"

import ArrowIcon from "../../images/icons/Arrow"

const IndexPage = ({ data }) => {
  const cardsSlider = data.allSliderEn.edges[0].node
  const cards = data.allCardsEn.edges.sort(
    (a, b) => -a.node.createdAt.localeCompare(b.node.createdAt)
  )
  const cardsTrending = data.allTrendingEn.edges[0].node
  const topics = data.allTopicsEn.edges
  const collections = data.allCollectionsEn.edges.sort(
    (a, b) => -a.node.updatedAt.localeCompare(b.node.updatedAt)
  )

  return (
    <Layout>
      <SEO
        title="Home"
        canonical={`${process.env.GATSBY_HOST_URL}/en`}
        image="https://nizam.app/nizam-cover.jpg"
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/pt`}
      />

      <CardCarousel cards={cardsSlider.cards.slice(0, 3)} />
      {/* <FeaturedSlider cards={cardsSlider.cards.slice(0, 1)} /> */}

      <div className="main-content" style={{ marginTop: 50 }}>
        <HomeSection
          name="New Content"
          cards={cards.slice(0, 4)}
          customClass="home-section-scroller"
        />

        <div
          className="home-section home-section-topics-container"
          style={{ marginTop: 50 }}
        >
          <div className="home-section-header">
            <span>Popular Topics</span>
          </div>

          <div className="home-section-carousel">
            <div className="home-section-topics">
              {topics.map(topic => (
                <Link
                  to={`/en/topics/${topic.node.slug}`}
                  className="home-section-topic"
                >
                  <div
                    className="home-section-topic-placer"
                    style={{ backgroundColor: topic.node.color }}
                  >
                    <span>{topic.node.name.substring(0, 1)}</span>
                  </div>

                  <div className="home-section-topic-info">
                    <h3>{topic.node.name}</h3>
                    <span>
                      {
                        cards.filter(
                          card => card.node.topic.slug === topic.node.slug
                        ).length
                      }{" "}
                      reads
                    </span>
                  </div>

                  <ArrowIcon />
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div
          className="home-section home-section-popular"
          style={{ marginTop: 50 }}
        >
          <div className="home-section-header">
            <span>Most Accessed</span>
          </div>

          {cardsTrending.cards.slice(0, 2).map(card => (
            <PopularCard card={card} />
          ))}
        </div>

        <div
          className="home-section home-section-topics-container"
          style={{ marginTop: 50, marginBottom: 50 }}
        >
          <div className="home-section-header">
            <span>Updated collections</span>
          </div>

          <div className="home-section-carousel">
            <div className="home-section-topics">
              {collections.map(collection => (
                <Link
                  to={`/en/collections/${collection.node.slug}`}
                  className="home-section-topic home-section-topic-collection"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "0.2fr 0.8fr",
                  }}
                >
                  <div
                    className="home-section-topic-placer"
                    style={{
                      width: 61,
                      height: 61,
                      backgroundImage: `url(${collection.node.imageUrl})`,
                      backgroundSize: "cover",
                      borderRadius: "50%",
                    }}
                  ></div>

                  <div className="home-section-topic-info">
                    <h3>{collection.node.name}</h3>
                    <span>{collection.node.cards.length} reads</span>
                  </div>

                  <ArrowIcon />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allCardsEn {
      edges {
        node {
          name
          slug
          imageUrl
          createdAt
          type
          topic {
            name
            slug
            color
          }
          tags {
            name
          }
          meta {
            content
            author
          }
        }
      }
    }

    allTopicsEn(limit: 6) {
      edges {
        node {
          name
          slug
          color
        }
      }
    }

    allCollectionsEn(limit: 6) {
      edges {
        node {
          name
          slug
          imageUrl
          createdAt
          updatedAt
          cards {
            name
            slug
          }
        }
      }
    }

    allTagsEn {
      edges {
        node {
          name
          slug
        }
      }
    }

    allSliderEn {
      edges {
        node {
          cards {
            name
            slug
            imageUrl
            type
            topic {
              name
              slug
              color
            }
          }
        }
      }
    }

    allTrendingEn {
      edges {
        node {
          cards {
            name
            slug
            imageUrl
            type
            topic {
              name
              slug
              color
            }
            tags {
              name
            }
            meta {
              content
              author
            }
          }
        }
      }
    }
  }
`
